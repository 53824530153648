import React from 'react'
import loadable from '@loadable/component'

const PortableText = loadable(() => import('../PortableText'))

const HeadingContent = ({ title, subtitle, hideSubtitle, text }) => (
  <>
    {subtitle && (
      <p
        className={`${
          hideSubtitle ? 'hidden md:block' : ''
        } text-base md:text-22 font-roboto font-bold text-white tracking-subtitle`}
      >
        {subtitle}
      </p>
    )}
    {title && (
      <h1 className="my-4 text-28 md:text-36 tracking-super md:tracking-header-lg font-roboto leading-none text-white uppercase md">
        {title}
      </h1>
    )}
    {text && (
      <div className="grid grid-cols-12">
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <div className="max-w-header-content">
            <PortableText blocks={text} isLight isHeader />
          </div>
        </div>
      </div>
    )}
  </>
)

export default HeadingContent
